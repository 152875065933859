<template>
  <NuxtLink :to="url" :class="getClasses">
    <slot /> <span v-if="arrow">→</span>
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: 'default'
  },
  arrow: {
    type: Boolean,
    default: false
  },
  index: {
    type: Number,
    default: 1
  }
})

const getClasses = computed(() => {
  // let classes = 'btn'
  let classes= ''
  // if (props.type) classes += ' btn--'+ props.type

  if (props.type === 'primary') classes += 'rounded-md transition bg-[#096b72] hover:bg-[#07484b] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
  if (props.type === 'black') classes += 'rounded-md transition bg-[#090d16] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900'
  if (props.type === 'secondary') classes += 'text-sm rounded-md transition font-semibold leading-6 py-2.5 px-3.5 text-gray-900 hover:bg-[#f7f7f8]'
  if (props.type === 'link')  classes += 'text-sm font-semibold leading-6 text-[#6073EE]'

  if (props.size === 'large') classes += ' btn--lg'
  if (props.size === 'small') classes += ' btn--sm'
  // if (props.arrow) classes += ' btn--arrow'
  return classes
})

</script>